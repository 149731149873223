export const addError = (message: any) => {
	let errors = [],
		storedErrors = localStorage.getItem('errors');
	if (storedErrors) {
		errors = JSON.parse(storedErrors);
	}

	errors.push(message);
	localStorage.setItem('errors', JSON.stringify(errors));
};

export const resetErrorByCell = async (errorCell: any) => {
	const storedErrors = localStorage.getItem('errors');
	if (storedErrors) {
		let parsedErrors = JSON.parse(storedErrors);
		parsedErrors = parsedErrors?.filter?.((storedError: any) => {
			return storedError.data.request.cell !== errorCell;
		});

		localStorage.setItem('errors', JSON.stringify(parsedErrors));
	}
	// eslint-disable-next-line
	await Excel.run(async (context) => {
		try {
			const comments = context.workbook.comments.getItemByCell(errorCell);
			if (comments) comments.delete();
			await context.sync();
		} catch {
			await context.sync();
		}
	});
};

export const getErrors = () => {
	let errors = [],
		storedErrors = localStorage.getItem('errors');
	if (storedErrors) {
		errors = JSON.parse(storedErrors);
	}

	return errors;
};

export const clearErrors = () => {
	localStorage.removeItem('errors');
};
