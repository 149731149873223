export default {
  buttons: {
    publish: 'Publish',
    edit: 'Edit',
    save: 'Save',
    cancel: 'Cancel'
  },
  globalFilterButton: {
    0: 'Apply',
    1: 'Applying',
    2: 'Applied!'
  },
  notifications: {
    tile: {
      create: {
        success: function (title: string) {
          return `tile '${title}' is created.`;
        },
        pending: function (title: string) {
          return `tile '${title}' is being created.`;
        },
        failure: function (title: string) {
          return `tile '${title}' could not be created. Please try again.`;
        }
      },

      edit: {
        success: function (title: string) {
          return `tile '${title}' is updated.`;
        },
        pending: function (title: string) {
          return `tile '${title}' is being updated.`;
        },
        failure: function (title: string) {
          return `tile '${title}' could not be updated. Please try again.`;
        }
      },

      delete: {
        success: function (title: string) {
          return `tile '${title}' is deleted.`;
        },
        pending: function (title: string) {
          return `tile '${title}' is being deleted.`;
        },
        failure: function (title: string) {
          return `tile '${title}' could not be deleted. Please try again.`;
        }
      }
    },
    dashboard: {
      publish: {
        success: (title = '') => 'Dashboard saved and published successfully.',
        pending: (title = '') => 'Dashboard is being saved',
        failure: (title = '') => 'Failed!'
      },

      edit: {
        success: (title = '') => 'Dashboard is updated!',
        pending: (title = '') => 'Dashboard is being updated.',
        failure: (title = '') => 'Failed!'
      },

      delete: {
        success: 'Dashboard was deleted successfully.',
        failure: 'Could not delete dashboard.'
      }
    }
  }
};
