export const getSheetByCellString = async (cellReference: string) => {
	try {
		//eslint-disable-next-line
		return await Excel.run(async (context) => {
			const splitCellString = cellReference.split('!');
			if (splitCellString.length !== 2) {
				throw new Error("Invalid cell reference format. Expected format: 'SheetName!CellAddress'");
			}

			let sheetName = splitCellString[0];
			if (sheetName.includes(' ') && sheetName.startsWith("'") && sheetName.endsWith("'")) {
				sheetName = sheetName.slice(1, -1);
			}

			const workbook = context.workbook;
			const sheet = workbook.worksheets.getItem(sheetName);
			sheet.load('id, name');

			await context.sync();

			return {
				sheetName: sheet.name,
				sheetId: sheet.id
			};
		});
	} catch (error) {
		console.error(error);

		return null;
	}
};
