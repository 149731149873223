// TODO responsedata type

import { getSheetByCellString } from '../get-sheet-by-cell-string/index.get-sheet-by-cell-string';

/**
 * Sets response data to excel, skips selected cell to preserve cell formula
 * @param formattedData assumes that the data has already been collected and assigned from the action
 * @cell the selected cell thats saved to the cell's formula so it can be called again
 */
export const setExcelData = (formattedData: any, cell: string, payload: any): any => {
	// eslint-disable-next-line
	Excel.run(async (context) => {
		const sheetObject = await getSheetByCellString(cell);

		const sheetId = sheetObject?.sheetId || null;
		if (sheetObject?.sheetName) {
			const sheet = context.workbook.worksheets.getItem(sheetObject?.sheetName);
			const selectedCell = sheet.getRange(cell);
			await context.sync();

			selectedCell.load(['columnIndex', 'rowIndex']);
			await context.sync();
			//TODO how can this be tidied up?
			const headerRange = sheet.getRangeByIndexes(
				selectedCell.rowIndex,
				selectedCell.columnIndex + 1,
				1,
				formattedData.meta.shiftedHeader.length
			);
			headerRange.load('address');
			await context.sync();
			setValues(headerRange, [formattedData.meta.shiftedHeader]);

			const valuesRange = sheet.getRangeByIndexes(
				selectedCell.rowIndex + 1,
				selectedCell.columnIndex,
				formattedData.data.length,
				formattedData.header.length
			);
			valuesRange.load('address');
			await context.sync();
			setValues(valuesRange, formattedData.data);

			const fullRange = sheet.getRangeByIndexes(
				selectedCell.rowIndex,
				selectedCell.columnIndex,
				formattedData.data.length,
				formattedData.header.length
			);
			fullRange.load('address');
			await context.sync();

			// eslint-disable-next-line
			Office.context.document.settings.set(
				`${cell}`,
				JSON.stringify({
					jsonPayload: payload,
					fullRange: fullRange.address,
					header: headerRange.address,
					values: valuesRange.address,
					sheetId
				})
			);
		}
		// eslint-disable-next-line
		Office.context.document.settings.saveAsync();
	}).catch(function (error) {
		console.log('Error: ' + error);
	});
};

const setValues = (selectedRange: any, data: any) => {
	selectedRange ? (selectedRange.values = data) : null;
};
