import { getSheetByCellString } from '../get-sheet-by-cell-string/index.get-sheet-by-cell-string';

/* Method to GET cell values by:
 - fetching the cell that the function came from to get the 'active' sheet (since it can be called from anywhere not just where the user is)
 - using the selectedRange string to then fetch the values.
 */
const handleExcelValues = async (invokedCell: string, selectedRange: string) => {
	const sheetObject = await getSheetByCellString(invokedCell);
	if (sheetObject?.sheetName) {
		//eslint-disable-next-line
		return await Excel.run(async (context) => {
			const sheet = context.workbook.worksheets.getItem(sheetObject.sheetName);
			const range = sheet.getRange(selectedRange);
			range.load('values');

			return context.sync().then(() => {
				return range.values;
			});
		});
	}
};

/* To satisfy the requirements to:
  - simulate named parameters.
	- avoid using an object in the function bar.

	this method taes the 3 dimensional excel array and retrieves the series of strings used to set parameter values.
	It handles stringified arrays and compound selected cell values (so long as they use a leading '!' operator).
	By the end, there should return a nice formatted object of the payload JSON and the target URL for query processing.
*/
export const rebuildPayloadAsObject = async (arg: any, cell: string) => {
	let payload: any = {},
		url = '';
	for (const [subIndex, subArg] of arg.entries()) {
		for (const subSubArg of subArg) {
			for (const string of subSubArg) {
				if (subIndex === 0) {
					url = string;
				} else {
					let valueArguments: any = string;
					valueArguments = valueArguments.split('=');
					let key = valueArguments[0].trim();
					payload[key] = '';
					let parsedArgValue = valueArguments[1];
					if (parsedArgValue.includes('[')) {
						let final = parsedArgValue.split('');
						final.pop();
						final.shift();
						final = final.join('').split(', ');
						let subsetArray: any = [];
						let finalResults = await Promise.all(
							final.map(async (parameterArgument: string) => {
								if (parameterArgument.charAt(0) === '!') {
									let subset = await handleExcelValues(cell, parameterArgument.substring(1));
									if (subset) {
										const flattenedSubset = subset.flat();

										return subsetArray.concat(flattenedSubset.flat());
									}
								}

								return parameterArgument;
							})
						);
						if (subsetArray.length > 0) {
							finalResults.concat(subsetArray);
						}

						payload[key] = finalResults.flat();
					} else {
						let finalValue = [parsedArgValue];
						if (parsedArgValue.charAt(0) === '!') {
							let subset = await handleExcelValues(cell, parsedArgValue.substring(1));

							finalValue = subset ? subset.flat() : [parsedArgValue];
						}

						payload[key] = finalValue;
					}
				}
			}
		}
	}

	return { payload, url };
};
