export const clearRangeFromId = async (id: string, rangeString: string, headerRangeString: string) => {
	// eslint-disable-next-line
	await Excel?.run?.(async (context) => {
		const workbook = context.workbook;
		const worksheet = workbook.worksheets.getItemOrNullObject(id);

		const range = worksheet.getRange(rangeString);
		range.clear();
		await context.sync();

		const headerRange = worksheet.getRange(headerRangeString);
		headerRange.clear();
		await context.sync();
	});
};
