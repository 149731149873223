import axios, { type AxiosPromise } from 'axios';
import { responseHandler } from './response-handler';
import { toast } from 'react-hot-toast';
import langEn from '../data/lang.en';
import { type ArgumentsByGInstanceAxiosType } from '../models/types';

export const httpRequest = <GResponseType, GSecondResponseType = null, GInstancAxiosType = 'handlers'>({
	method,
	url,
	data,
	resolveResponse,
	handleError,
	resolveAfterRequest,
	hasNestedDataResponse = true,
	signal,
	hasToast = false,
	langMethod,
	toastObject,
	isPromise = false
}: ArgumentsByGInstanceAxiosType<GResponseType, GSecondResponseType, GInstancAxiosType>):
	| AxiosPromise<GResponseType>
	| undefined => {
	const lang = langEn;
	let user: any = localStorage.getItem('user');

	if (user) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		user = JSON.parse(user);
	}

	if (toastObject) {
		toastObject?.statusFunction?.((prev: any) => ({
			...prev,
			state: 'pending'
		}));
	}

	// creating id for toast that we can track and update accordingly.
	const toastId = undefined;

	if (hasToast && langMethod && toastObject) {
		const toastLoading =
			(toastObject?.component &&
				(lang.notifications as any)[toastObject.component][langMethod].pending(toastObject.name)) ||
			'';

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		toast.loading(toastLoading);
	}

	if (isPromise) {
		// is promise needs to return the whole block and cant use the .then logic which isnt great, but this sidesteps
		// used wherever we have Promise.all
		return axios({
			method,
			url,
			data,
			signal,
			headers: user?.token && { Authorization: `Bearer ${user.token}` }
		});
	}

	axios({
		method,
		url,
		data,
		signal,
		headers: user?.token && { Authorization: `Bearer ${user.token}` }
	})
		.then((res) => {
			const { dataResponse, meta } = responseHandler(res, hasNestedDataResponse);
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			resolveResponse?.(dataResponse, meta);

			if (toastObject) {
				toastObject?.statusFunction?.((prev: any) => ({
					...prev,
					state: 'resolved'
				}));
			}

			if (hasToast && langMethod) {
				toast.success(
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					(toastObject?.component &&
						(lang.notifications as any)[toastObject?.component][langMethod].success(toastObject.name)) ||
					'',
					{
						id: toastId
					}
				);
			}
		})
		.catch((err) => {
			if (toastObject) {
				toastObject?.statusFunction?.((prev: any) => ({
					...prev,
					state: 'rejected'
				}));
			}

			// TODO: kill nested
			if (hasToast && langMethod) {
				toast.error(
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					(toastObject?.component &&
						(lang.notifications as any)[toastObject?.component][langMethod].failure(toastObject.name)) ||
					'' ||
					'',
					{
						id: toastId
					}
				);
			}

			handleError?.(err);

			if (err.response && user?.token) {
				if (
					err.response.data?.message === 'Unauthenticated.' ||
					err.response.status === 405 ||
					err.response.status === 401
				) {
					localStorage.setItem('user', '');

					if (!window.location.href.includes('login') || !window.location.href.includes('forgot-password')) {
						// window.location.replace('/login');
					}
				}
			}
		})
		.finally(() => resolveAfterRequest?.());
};
