/* eslint-disable no-nested-ternary */
export const responseHandler = (
  response: {
    data: { data: [Record<string, object | any>];[k: string]: any };
    // need to investiage meta
    meta?: any;
  },
  hasNestedDataResponse: boolean
  // we have quite a few non json openapi compliant endpoints, this needs to be deprecated.
): { dataResponse: any; meta?: any } => {
  // handling meta right now seems a little funky, just checking if its deeply nested or not
  try {
    const responseMetaData = response.data.meta ? response.data.meta : null;

    return {
      dataResponse: hasNestedDataResponse ? response.data.data : response.data,
      meta: hasNestedDataResponse ? responseMetaData : response.meta[0]
    };
  } catch {
    return { dataResponse: response };
  }
};
