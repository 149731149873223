const isStaging = window.location.href.includes('https://tricorder.app-staging.consumer-edge.com');

const api = {
  jtubesRoot: {
    url: isStaging ? 'https://jtubes.app-staging.consumer-edge.com/api' : `${process.env.JTUBES}`
  },
  librarianApi: {
    url: isStaging ? 'https://librarian.app-staging.consumer-edge.com/api' : `${process.env.LIBRARIAN}`
  },
  authApi: {
    url: isStaging ? 'https://auth.app-staging.consumer-edge.com/api' : `${process.env.AUTH}`
  }
};

console.log(api);

export default api;
