export const formatExcelData = (actionResponse: any) => {
	const { data } = actionResponse?.data;
	const dataSeries = data?.series;
	if (actionResponse?.data?.error) {
		throw new Error('200 Response but no data, check network');
	}

	const flattenedData: any[] = [];

	dataSeries.forEach((serie: any) => {
		serie.data.forEach((dataPoint: any) => flattenedData.push(Object.values(dataPoint)));
	});
	// as excel data SHOULD be common, we should have a joined set of headers hey? keep a watch here for future bugs lol
	const header = dataSeries[0].header;
	const [, ...shiftedHeader] = header;

	return { data: flattenedData, header, meta: { shiftedHeader } };
};
